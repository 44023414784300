import React, { useEffect, useState } from "react";
import { firestoredb } from "../firebase";
import { collection, addDoc, query, getDocs } from "firebase/firestore";
import { FaArrowLeft, FaArrowRight, FaQuoteRight } from "react-icons/fa";
import styled from "styled-components";

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState<any>([]);

  const [index, setIndex] = useState(0);

  const fetchPost = async () => {
    await getDocs(collection(firestoredb, "studentfeedback")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setFeedbacks(newData);
        // console.log(feedbacks, newData);
      }
    );
  };

  useEffect(() => {
    fetchPost();
  }, []);

  useEffect(() => {
    const lastIndex = feedbacks.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, feedbacks]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 4000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <Wrapper>
      <section className="section">
        <div className="title">
          <h2>
            <span>/</span>Reviews
          </h2>
        </div>
        <div className="section-center">
          {feedbacks.map((review: any, reviewIndex: number) => {
            const { id, customerName, feedback } = review;
            let position = "nextSlide";
            if (reviewIndex === index) {
              position = "activeSlide";
            }
            if (
              reviewIndex === index - 1 ||
              (index === 0 && reviewIndex === feedbacks.length - 1)
            ) {
              position = "lastSlide";
            }
            return (
              <article key={id} className={position}>
                <img
                  className="person-img"
                  src="https://picsum.photos/200/300?random=1"
                  alt="user"
                ></img>
                <h4>{customerName}</h4>
                <p className="title">Student</p>
                <p className="text">{feedback}</p>
                <FaQuoteRight className="icon" />
              </article>
            );
          })}
          <button className="prev" onClick={() => setIndex(index - 1)}>
            <FaArrowLeft />
          </button>
          <button className="next" onClick={() => setIndex(index + 1)}>
            <FaArrowRight />
          </button>
        </div>
      </section>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
  .title span {
    font-size: 0.85em;
    color: hsl(21, 62%, 45%);
    margin-right: 1rem;
    font-weight: 700;
  }
  .section-center {
    margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    /* have to have a height */
    height: 450px;
    max-width: 650px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
  }
  .person-img {
    border-radius: 50%;
    margin-bottom: 1rem;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 4px solid hsl(210, 31%, 80%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    margin-top: 1rem;
  }
  article h4 {
    text-transform: uppercase;
    color: hsl(21, 62%, 45%);
    margin-bottom: 0.25rem;
  }
  .title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: hsl(209, 34%, 30%);
  }
  .text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
    color: hsl(210, 22%, 49%);
  }
  .icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: hsl(21, 62%, 45%);
  }
  .prev,
  .next {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    background: hsl(210, 22%, 49%);
    color: #fff;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.3s linear;
  }
  .prev:hover,
  .next:hover {
    background: hsl(21, 62%, 45%);
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s linear;
  }
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
`;

export default Feedback;
