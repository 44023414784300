import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { feedbackModel } from "../models/feedbackModel";
import { getDefaultEmulatorHost } from "@firebase/util";
import { useDispatch, useSelector } from "react-redux";
import { updateFeedback } from "../features/feedback/feedbackSlice";
import { firestoredb } from "../firebase";
import { collection, addDoc, query, getDocs } from "firebase/firestore";

const GiveFeedback = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const reviewModel: feedbackModel = useSelector(
    (state: any) => state.feedback.feedbackItem
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const addReview = async () => {
    try {
      const docRef = await addDoc(collection(firestoredb, "studentfeedback"), {
        ...reviewModel,
      });
      console.log("Created feedback with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding feedback: ", e);
    }
  };
  const handleSubmit = () => {
    if (
      reviewModel.customerName.trim() !== "" &&
      reviewModel.feedback.trim() !== ""
    ) {
      addReview();
      setShow(false);
    } else {
      console.log("required field missing");
      setShow(true);
    }
  };
  return (
    <>
      <Wrapper>
        <Button variant="primary" size="lg" onClick={handleShow}>
          Give Feedback
        </Button>
      </Wrapper>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Student Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="yourname">
              <Form.Label>Your Name *</Form.Label>
              <Form.Control
                type="text"
                placeholder="Full Name"
                autoFocus
                value={reviewModel.customerName}
                onChange={(e) =>
                  dispatch(
                    updateFeedback({
                      ...reviewModel,
                      customerName: e.target.value,
                    })
                  )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@email.com"
                value={reviewModel.email}
                onChange={(e) =>
                  dispatch(
                    updateFeedback({
                      ...reviewModel,
                      email: e.target.value,
                    })
                  )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="phone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone/Mobile Number"
                value={reviewModel.phone}
                onChange={(e) =>
                  dispatch(
                    updateFeedback({
                      ...reviewModel,
                      phone: e.target.value,
                    })
                  )
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="feedback">
              <Form.Label>Feedback *</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={reviewModel.feedback}
                onChange={(e) =>
                  dispatch(
                    updateFeedback({
                      ...reviewModel,
                      feedback: e.target.value,
                    })
                  )
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Wrapper = styled.section`
  Button {
    margin-bottom: 1rem;
    background-color: var(--clr-primary-5);
  }
`;
export default GiveFeedback;
