import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Home, Feedback, Pricing, Contact, Setup, PrivateRoute } from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="setup"
            element={
              <PrivateRoute>
                <Setup />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
