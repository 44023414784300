// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDx6492kNxT4sdqM4DkcZ3Vtx2l9t1zLs8",
  authDomain: "carfortest-8073e.firebaseapp.com",
  databaseURL: "https://carfortest-8073e.firebaseio.com",
  projectId: "carfortest-8073e",
  storageBucket: "carfortest-8073e.appspot.com",
  messagingSenderId: "1028037684119",
  appId: "1:1028037684119:web:38d4e4dd2ca61dec0172d2",
  measurementId: "G-4C62DR3972",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestoredb = getFirestore(app);
