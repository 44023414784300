import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./features/sidebar/sidebarSlice";
import feedbackReducer from "./features/feedback/feedbackSlice";
import priceReducer from "./features/price/priceSlice";

export const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    feedback: feedbackReducer,
    price: priceReducer,
  },
});
