import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute = ({ children }: any) => {
  const { user } = useAuth0();
  const adminUser = "purunep@hotmail.com";

  if (!user) {
    return <Navigate to="/" />;
  } else if (user && user.email === adminUser) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
