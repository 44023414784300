import React from "react";
export const links = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "pricing",
    url: "/pricing",
  },
  //   {
  //     id: 3,
  //     text: 'student feedback',
  //     url: '/feedback',
  //   },
  {
    id: 4,
    text: "Contact",
    url: "/contact",
  },
];
