import React from "react";
import styled from "styled-components";

const ContactPage = () => {
  return (
    <Wrapper className="section-center">
      <article className="content">
        Contact me for learning driving and for the road test at 647-709-5055
      </article>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  min-height: 60vh;
  display: grid;
  place-items: center;
  font-size: 1.5rem;
`;

export default ContactPage;
