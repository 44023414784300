import { createSlice } from "@reduxjs/toolkit";
import { feedbackModel } from "../../models/feedbackModel";

const defaultValue: feedbackModel = {
  customerName: "",
  email: "",
  date: new Date().toDateString(),
  phone: "",
  feedback: "",
  display: true,
};

const initialState = {
  feedbackItem: defaultValue,
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    updateFeedback: (state, action) => {
      state.feedbackItem = action.payload;
    },
  },
});

export const { updateFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
