import React, { useEffect, useState } from "react";
import { firestoredb } from "../firebase";
import { collection, updateDoc, query, getDocs, doc } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updatePrice } from "../features/price/priceSlice";
import { priceModel } from "../models/priceModel";
import { useAuth0 } from "@auth0/auth0-react";

const Price = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const adminUser = "purunep@hotmail.com";
  //   const [prices, setPrices] = useState<any>([]);
  const prices: priceModel[] = useSelector(
    (state: any) => state.price.priceItems
  );

  const [description1, setDescription1] = useState<any>("");

  const fetchPost = async () => {
    await getDocs(collection(firestoredb, "serviceprice")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        dispatch(updatePrice(newData));
      }
    );
  };

  useEffect(() => {
    fetchPost();
  }, []);
  const handleSubmit = (id: string) => {
    console.log(id);
    let updatedPrice = prices.filter((p) => p.id === id)[0];
    if (updatedPrice.description !== "") {
      const docRef = doc(firestoredb, "serviceprice", id);
      const data = {
        description: updatedPrice.description,
      };
      updateDoc(docRef, data)
        .then((docRef) => {
          console.log("Updated");
        })
        .catch((error) => {
          console.log(error);
        });
      //   addReview();
    } else {
      console.log("required field missing");
    }
  };
  const handleChange = (id: string, e: any, index: number) => {
    let newPrice = {
      ...prices.filter((p) => p.id === id)[0],
      description: e.target.value,
    };
    const arrCopy = [...prices];
    arrCopy[index] = newPrice;

    dispatch(updatePrice(arrCopy));
  };
  return (
    <Wrapper>
      {prices.map((price: any, index: number) => {
        return (
          <article key={price.id}>
            <Card style={{ width: "24rem" }}>
              <Card.Img
                variant="top"
                src="https://firebasestorage.googleapis.com/v0/b/carfortest-8073e.appspot.com/o/carpicture.jpg?alt=media&token=3352d5ec-e889-4181-9d07-62caca8f9d1f"
              />
              <Card.Body>
                <Card.Title>
                  {price.packageType} Package(Scarborough/Toronto)
                </Card.Title>
                <Card.Text>{price.description}</Card.Text>
                {user && user.email === adminUser && (
                  <Form>
                    <Form.Group className="mb-3" controlId="feedback">
                      <Form.Label>Description *</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={price.description}
                        onChange={(e) => handleChange(price.id, e, index)}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      onClick={() => handleSubmit(price.id)}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </article>
        );
      })}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  margin-top: 100px;
  display: grid;
  grid-template-columns: 400px 1fr;

  article {
    margin: 20px;
  }
  .card-title {
    background-color: hsl(22, 31%, 67%);
    padding: 13px;
    border: 1px solid #b1b0af;
    border-radius: 25px;
  }
`;

export default Price;
