import React from "react";
import styled from "styled-components";
import Feedback from "../components/Feedback";
import GiveFeedback from "../components/GiveFeedback";

const HomePage = () => {
  return (
    <Wrapper className="section-center">
      <article className="content">
        <h1>
          15 years of experience <br />
          as a Driving Instructor
        </h1>
<p>
  I teach defensive driving technique, knowedgle and Skills to pass the road test. Space to manueuver your vehicle away from conflict visibility and 
  blind spot to detect danger and the potential for conflict. Special attention is paid to parking skills and techniques. Make you familiarize with traffic
  signs rules of roads and highways. Give training on major and big roads and intersections.
</p>
        <p>
          We provide insurance discoun certificate which assist you for insurance discount and earlier road test eligibility.
          For quality services please contact Certified licensed Driving
          school instructor at <strong>647-709-5055</strong>
        </p>
      </article>
      <article className="feedback-container">
        <Feedback />

        <GiveFeedback />
      </article>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  min-height: 60vh;
  display: grid;
  place-items: center;
  .feedback-container {
    display: block;
  }

  p {
    line-height: 2;
    max-width: 45em;
    margin-bottom: 2rem;
    color: var(--clr-grey-5);
    font-size: 1rem;
  }
  .content {
    margin-top: 1rem;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 5rem);
    grid-template-columns: 1fr 1fr;
    gap: 8rem;
    h1 {
      margin-bottom: 2rem;
    }
    p {
      font-size: 1.25rem;
    }
    .hero-btn {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
    }
    .feedback-container {
      display: block;
      position: relative;
    }
    .main-img {
      width: 100%;
      height: 550px;
      position: relative;
      border-radius: var(--radius);
      display: block;
      object-fit: cover;
    }
    .accent-img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 250px;
      transform: translateX(-50%);
      border-radius: var(--radius);
    }
    .feedback-container::before {
      /* content: ""; */
      position: absolute;
      width: 10%;
      height: 80%;
      background: var(--clr-primary-9);
      bottom: 0%;
      left: -8%;

      /* border-radius: var(--radius); */
    }
  }
`;

export default HomePage;
