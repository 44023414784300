import { createSlice } from "@reduxjs/toolkit";
import { priceModel } from "../../models/priceModel";

const defaultValue: priceModel = {
  id: "",
  description: "",
  packageType: "",
};

const initialState = {
  priceItems: [defaultValue],
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    updatePrice: (state, action) => {
      state.priceItems = action.payload;
    },
  },
});

export const { updatePrice } = priceSlice.actions;

export default priceSlice.reducer;
