import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Price from "../components/Price";
import styled from "styled-components";

const PricingPage = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <Price />
          </Col>
          <Col>
            <p className="info">
              Please note that each private lesson is of 60 minutes in duration.
              This price covers Toronto Port Union and Toronto Metro East.
            </p>
            <p>
              We provide Road test services all around Ontario. Call me to
              schedule a lesson appointment: 647-709-5055
            </p>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .info {
    margin-top: 120px;
    font-weight: 600;
    font-size: larger;
  }
`;
export default PricingPage;
